$pearson-white-grey: #f5f5f5;
$pearson-chalk-white: #FFFFFF;
$pearson-dark-blue: #005a70;
$pearson-marine-turquoise: #12B2A6;

.navigation-bar {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  background-color: $pearson-dark-blue;
  color: $pearson-chalk-white;
  .box {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    max-width: 1280px;
    width: 100%;
    .navigation-links {
      .nav-links {
        padding: 0px;
        li {
          margin: initial 6px;
          a {
            @extend %nav-links-abstract;
            color: $pearson-white-grey;  
            &:hover {
              border-bottom: 3px solid $pearson-white-grey; 
            }
          }
          a.dark {
            @extend %nav-links-abstract;
            color: $pearson-white-grey;
            &:hover {
              color: $pearson-white-grey;
              border-bottom: 3px solid $pearson-white-grey;
            }
            .activeTab {
              color: $pearson-chalk-white;
            }
          }
          a.activeTab {
            border-bottom-color: $pearson-marine-turquoise;
            color: $pearson-chalk-white;
            &:hover {
              border-bottom: 3px solid $pearson-marine-turquoise;
            }
          }
        }
      }      
    }
    .learning-provider {
      display: inline-flex;
      align-items: center;
      padding-left: 6px;
      .search-icon {
        background-color: transparent;
        height: 36px;
        padding: 5px 12px;
        margin: 3px 0px 3px 6px;
        border: none;
      }
      .help-icon {
        padding: 5px 12px;
        margin: 3px 0px 3px 36px;
      }
    }
  }
  @media all and (min-width: 800px) { 
    .box {
      justify-content: space-between;
    }  
  }
}
%nav-links-abstract {
  padding: 8px 15px 5px;
  display: flex;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}
