@import "./variables";

.gr-fieldset, fieldset  {
  border: none !important;
  padding:0;
}

.gr-fieldset legend {
  margin-bottom:8px;
}
.gr-radio {
  margin-bottom: 14px;
  min-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.gr-radio input[type=radio] {
  opacity: 0;
  position: absolute;
}

.gr-radio input[type=radio]:focus~span {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px $focus
}

.gr-radio input[type=radio]+label {
  display: inline-block;
  line-height: 18px;
  padding-left: 28px;
  font-size:14px;
  cursor:pointer;
}

.gr-radio input[type=radio]~span {
  -webkit-box-sizing: content-box;
  border: 2px solid #c7c7c7;
  background:white;
  border-radius: 50%;
  box-sizing: content-box;
  color: #6a7070;
  display: block;
  height: 5px;
  left: 0;
  padding: 3px 6px 6px 3px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 5px;
}

.gr-radio input[type=radio]~span svg {
  height: 18px;
  opacity: 0;
  width: 18px;
}
.gr-radio input[type=radio]:checked~span svg {
  opacity: 1;
  top: -8px !important;
  position: relative;
  left: -5px;
  fill: $primary;
}

.gr-radio input[type=radio]:disabled~span svg {
  opacity: 1;
  fill: $neutral-med-two;
  top: -5px;
  left: -5px;
  position: relative;
}
