// Primary Pallette
$primary: #047A9C; // Pearson Blue
$secondary: #FFB81C; // Sunshine Yellow
$white: #ffffff;

// Neutral Colors
// High Contrast Tones
$neutral-high-one: #252525; // Charcoal
$neutral-high-two: #6A7070; // Medium Gray

// Medium Tones
$neutral-med-one: #A9A9A9; // Steel
$neutral-med-two: #C7C7C7; // Concrete
$neutral-med-three: #D9D9D9; // Alto
$neutral-med-four: #E9E9E9; // Moonlight

// Light Tones
$neutral-light-one: #EEEEEE; // Stainless
$neutral-light-two: #F5F5F5;  // White Gray

// Conditional Colors
$condition-one: #DB0020; // Alert Red
$condition-two: #038238; // Confirm Green
$condition-three: #DA0474; // Notification Pink

// Themes
// Aqua
$theme-one-light: #CAEFEE; // Light Aqua
$theme-one-med: #76D5D4; // Medium Aqua
$theme-one-dark: #19A5A3;  // Dark Aqua

// Violet
$theme-two-light: #F2E5F1; // Light Violet
$theme-two-med: #895B9A; // Medium Violet
$theme-two-dark: #633673;  // Dark Violet

// Chartreuse
$theme-three-light: #F6F8CC; // Light Chartreuse
$theme-three-med: #D2DB0E; // Medium Chartreuse
$theme-three-dark: #B0B718;  // Dark Chartreuse

// Indigo
$theme-four-light: #D9E6F1; // Light Indigo
$theme-four-med: #356286; // Medium Indigo
$theme-four-dark: #1E496C;  // Dark Indigo

// Lime
$theme-five-light: #DFF5D5; // Light Lime
$theme-five-med: #66BE3E; // Medium Lime
$theme-five-dark: #288500;  // Dark Lime

// Sky
$theme-six-light: #D6ECF4; // Light Sky
$theme-six-med: #80C5DD; // Medium Sky
$theme-six-dark: #46A9CB;  // Dark Sky

// Canary
$theme-seven-light: #FAEBC3; // Light Canary
$theme-seven-med: #F5C54C; // Medium Canary
$theme-seven-dark: #DEA30D;  // Dark Canary

// focus state
$focus: #1977D4; // Blue focus circle

// Typography
$font-size: 16; // Default

// Effects
$box-shadow: rgba(0, 0, 0, .25);
$border: $neutral-med-two;

// Grid
$sm-gap: 16px;
$med-gap: 24px;
