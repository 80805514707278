.search-bar { 
  .search-container {
    display: flex;
    background-color: #ffffff;
    height: 36px;
    .gr-form-element:first-of-type {
      border: 0px;
    }
    .input {
      flex-grow: 1;
      .gr-form-element {
        border-left: 1px solid #C7C7C7;
        margin-right: 4px;
      }      
    }
    .btn-container {
      border-left: 1px solid #C7C7C7;
      padding: 1px;
      width: 50px;      
    }
  }
}