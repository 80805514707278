@import "~@pearson-ux/gravity-css/dist/gravity";
@import "./components/pearson/variables";

$pearson-dark-blue: #005a70;

html,
body {
  background-color: $neutral-light-two;
  height: 100vh;
}
body.wait,
body.wait * {
  cursor: wait !important;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1 0 auto;
  }
  .footer {
    flex-shrink: 0;
    height: 100px;
    padding: 1em;
    background-color: $pearson-dark-blue;
    ul li {
      border-right-color: $white;
    }
  }
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

h1 { 
  @extend .gr-h1; 
}
h2 { 
  @extend .gr-h2; 
  text-shadow: none;
  color: $neutral-high-one !important;
  font-size: 18px;
  padding: 0px;
  margin-top: 1px;
}
h3 { 
  @extend .gr-h3; 
  margin-bottom: 18px;
}
h4 {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 18px;
  padding-left: 18px;
}

p { @extend .gr-font-normal; }

dl {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  dt {
    @extend .gr-meta;
    line-height: 21px;
    padding-right: 12px;
    margin: 0px;
  }
  dd {
    margin: 0px;
  }
}

.opaque { opacity: 1 !important; }
.semi-bold { @extend .gr-semi-bold; }
.warning {
  color: $secondary !important;
  fill: $secondary !important;
}
.error { @extend .gr-condition-one; }
.success { @extend .gr-condition-two; }
.information { @extend .gr-primary; }
.transparent {
  background-color: transparent !important;
  border: none !important;
}

.dropdown-item,
a  {
  cursor: pointer !important;
}

.dropdown-item:hover {
  background-color: #1977D4;
  color: #ffffff;
}

.btn.focus,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
}

.gr-input.error,
.gr-select.error {
  display: flex;
  align-items: center;
  border: 1px solid $condition-one;
  fill: $condition-one;
}

.alert-message {
  width: 100%;
  .alert {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 42px;
    margin-bottom: 24px;
    .icon {
      margin-right: 18px;
    }
  }
}

.catalog.card-container .info {
  display: grid;
  align-items: center;
  grid-gap: 0px;
}

.main {
  padding: 0px 20px;
  height: fit-content
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  pearson-loader {
    width: 260px;
    height: 80px;
  }
}

.action-button button {
  margin: 3px 0px 0px 0px;
}

.jumbotron {
  margin: 0px initial;
  padding: 40px 4px 18px !important;
  @extend .transparent;
}

.back-link {
  grid-area: back-link;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 3px 0px 0px;
  margin: 6px 0px;
  font-size: 13px;
  font-weight: 700;
}

.header {
  position: relative;
    .status {
      display: flex;
      flex-direction: column;
      dl { display: flex; 
        dt { width: 210px; }
      }
    }
}


.actions {
  display: flex;
  align-items: center;
  .action-status {
    width: 100%;
    .alert {
      margin: 0px 0px 0px 18px;
      padding: 8px 12px;
    }
  }
  .edit-buttons button { 
      margin: 3px 0px 0px 0px;
      margin-right: 12px;
  }
  .gr-btn:disabled .icofjn {
    fill: $neutral-high-one;
  }
}

.spinner {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.modal-dialog {
  .modal-header {
    padding: 18px 30px;
    height: 100px;
    border-bottom: none;
    align-items: flex-end;
    button.close {
      font-size: 28px;
      align-self: flex-start;
    }
  }
  .modal-body {
    padding: 0px 40px;
  }
  .modal-footer {
    border-top: none;
  }
}

.search-bar {
  margin-bottom: 6px;
}
@media all and (min-width: 800px) {
  .search-bar {
    max-width: 800px;
  }
}
.sort {
  justify-self: flex-end;
  width: 340px;
  margin-bottom: 6px;
}
.search-sort-container {
  display: grid;
  @media all and (min-width: 800px) {
    grid-template-columns: auto 350px;
  }
}

.actions {
  grid-area: actions;
  justify-self: center;
}

.warning-details ol,
.error-details ol {
  margin-bottom: 12px;
  padding-left: 20px;
}

.learner-grades {
  .view-learner-grades .search-bar {
    margin-bottom: 24px;
  }
}

.icons {
  grid-area: i;
  justify-self: center;
  align-self: center;
  width: 24px;
  label {
    margin-bottom: 0px;
  }
}

.name { grid-area: name; }
.dob { grid-area: dob; }
.id { grid-area: id; }
.grade { grid-area: grade; }
.ranking { grid-area: ranking; }
.warning-details { grid-area: warnings }
.grade,
.ranking { 
  dd {
    max-width: 120px;
  }
}

.view-qualifications {  
  .info {
    .qualification { grid-area: qualification; }
    .code { grid-area: code; }
    .status { grid-area: status };
    .number-of-learners { grid-area: number-of-learners; }
    .number-of-appeals { grid-area: number-of-appeals; }
    .learners-processed { grid-area: learners-processed; }
    .last-updated { grid-area: last-updated; }  
  }
  .qualification-card .info {
    .qualification { grid-area: qualification; }
    .code { grid-area: code; }
    .status { grid-area: status };
    .number-of-learners { grid-area: number-of-learners; }
    .number-of-appeals { grid-area: number-of-appeals; }
    .learners-processed { grid-area: learners-processed; }
    .last-updated { grid-area: last-updated; } 
    grid: 
      [row1-start] "i qualification       actions           " [row1-end] 
      [row2-start] ". code                code              " [row2-end]
      [row3-start] ". status              status            " [row3-end]
      [row4-start] ". number-of-learners  number-of-learners" [row4-end]
      [row5-start] ". learners-processed  learners-processed" [row5-end]
      [row6-start] ". last-updated        last-updated      " [row6-end]
      / 80px 1fr 80px;   
    @media all and (min-width: 800px) {
      grid: [row1-start] "i qualification       qualification       qualification actions       " [row1-end] 
            [row2-start] ". code                status              status        status        " [row2-end]
            [row3-start] ". number-of-learners  learners-processed  last-updated  last-updated  " [row3-end] 
            / 80px 1fr 1fr 1fr 80px;
    }
    @media all and (min-width: 1200px) {
      grid: [row1-start] "i qualification qualification qualification       qualification       qualification actions       " [row1-end] 
            [row2-start] ". code          status        number-of-learners  learners-processed  last-updated  last-updated  " [row2-end]
            / 80px 3fr 5fr 4fr 4fr 5fr 80px;
    }
  }
  .qualification-appeal-card .info {
      grid: [row1-start] "i qualification       actions           " [row1-end] 
            [row2-start] ". code                code              " [row2-end]
            [row3-start] ". number-of-learners  number-of-learners" [row3-end]
            [row4-start] ". number-of-appeals   number-of-appeals " [row4-end]
            / 80px 1fr 80px;   
      @media all and (min-width: 800px) {
        grid: [row1-start] "i qualification  qualification       qualification      actions " [row1-end] 
              [row2-start] ". code           number-of-learners  number-of-appeals  .       " [row2-end] 
              / 80px 1fr 1fr 1fr 80px;
    }
    @media all and (min-width: 1200px) {
      grid: [row1-start] "i qualification code number-of-learners number-of-appeals actions" [row1-end] 
            / 80px 3fr 2fr 2fr 2fr 80px;
    }
  }
}

.learner-grade-card,
.learner-grade-card-gradable{
  .catalog.card-container .info {
    display: grid;
    gap: 0px 0px;
    grid: [row1-start] "i name    " [row1-end] 
          [row2-start] ". dob     " [row2-end] 
          [row3-start] ". id      " [row3-end] 
          [row4-start] ". grade   " [row4-end] 
          [row5-start] ". ranking " [row5-end] 
          [row6-start] ". warnings" [row6-end] 
          / 80px 1fr;
  }
}
.learner-grade-card {
  .catalog.card-container .info {
    @media all and (min-width: 700px) {
      grid: [row1-start] "i name      name       name     "[row1-end] 
            [row2-start] ". dob       id         ranking  "[row2-end] 
            [row3-start] ". warnings  warnings   warnings "[row3-end] 
            / 80px 1fr 1fr 1fr;
    }
    @media all and (min-width: 1200px) {
      grid: [row1-start] "i name     dob      id       ranking  "[row1-end] 
            [row2-start] ". warnings warnings warnings warnings "[row2-end] 
            / 80px 1fr 1fr 1fr 1fr;
    }  
  }
}  
.learner-grade-card-gradable {
  .catalog.card-container .info {
    @media all and (min-width: 800px) {
      grid: [row1-start] "i name      name       name     name     " [row1-end] 
            [row2-start] ". dob       id         grade    ranking  " [row2-end] 
            [row3-start] ". warnings  warnings   warnings warnings " [row3-end] 
            / 80px 3fr 3fr 2fr 2fr;
    }
    @media all and (min-width: 1200px) {
      grid: [row1-start] "i name     dob      id       grade    ranking  " [row1-end] 
            [row2-start] ". warnings warnings warnings warnings warnings " [row2-end] 
            / 80px 3fr 3fr 3fr 2fr 2fr;
      }
  }
}
.edit-learner-grades 
.learner-grade-card-gradable {
  .catalog.card-container .info  {
    gap: 5px;
    .grade,
    .ranking {
      dt { width: 65px; }
      dd { width: 120px; }
      .error-state {
        margin-top: 8px;
        .icon-18 {
          align-self: flex-start;
          width: 30px;
        }
      }
    }
    @media all and (min-width: 600px) {
      grid: [row1-start] "i name      name     " [row1-end]
            [row2-start] ". dob       id       " [row2-end] 
            [row3-start] ". grade     ranking  " [row3-end] 
            / 80px 1fr 1fr;
    }
    @media all and (min-width: 900px) {
      grid: [row1-start] "i name      name      name      name      " [row1-end] 
            [row2-start] ". dob       id        grade     ranking   " [row2-end] 
            / 80px 1fr 1fr 1fr 1fr;
    }
    @media all and (min-width: 1200px) {
      grid: [row1-start] "i name dob id  grade ranking  " [row1-end] 
      / 80px 1fr 1fr 1fr 1fr 1fr;
    }
  }
}

.fileline-error-card {
  .catalog.card-container .info {
    display: grid;
    gap: 0px 0px !important;
    .row-number { grid-area: row-number; }
    .fileline-data { grid-area: fileline; }
    .error-details { grid-area: errors; }
    grid: [row1-start] "i row-number"[row1-end] 
          [row2-start] ". fileline  "[row2-end] 
          [row3-start] ". errors    "[row3-end] 
          / 80px 1fr;
    @media all and (min-width: 800px) {
      .error-details {
        ol { margin: 0px;}
      }
      grid: [row1-start] "i row-number  fileline"[row1-end] 
            [row2-start] ". errors      errors  "[row2-end] 
            / 80px 1fr 2fr;
    }
    @media all and (min-width: 1200px) {
      grid: [row1-start] "i row-number  fileline"[row1-end] 
            [row2-start] ". errors      errors  "[row2-end] 
            / 80px 1fr 3fr;
    }
  }
}

.file-history {
  .file-card-list {
    grid-area: file-card-list;
    .file-card {
      .info {
        display: grid;
        gap: 0px 0px !important;
        .file-name { grid-area: file-name; }
        .uploaded-date { grid-area: uploaded-date; }
        .uploaded-by-user { grid-area: uploaded-by-user; }
        .procesed { grid-area: procesed; }
        .warnings { grid-area: warnings; }
        .upload-type { grid-area: upload-type; }
        grid: [row1-start] "i file-name           actions" [row1-end] 
              [row2-start] ". uploaded-date       .      " [row2-end] 
              [row3-start] ". uploaded-by-user    .      " [row3-end] 
              [row4-start] ". procesed            .      " [row4-end] 
              [row5-start] ". warnings            .      " [row5-end] 
              [row6-start] ". upload-type         .      " [row6-end] 
              / 80px 1fr 80px;
        @media all and (min-width: 800px) {
          grid: [row1-start] "i file-name           file-name           file-name         actions       " [row1-end] 
                [row2-start] ". ploaded-date       uploaded-by-user    uploaded-by-user  .             "[row2-end]
                [row3-start] ". procesed            warnings            upload-type       .             " [row3-end] 
                / 80px 3fr 2fr 2fr 80px;
        }
        @media all and (min-width: 1200px) {
          grid: [row1-start] "i file-name       file-name           file-name     file-name  file-name    actions " [row1-end] 
                [row2-start] ". uploaded-date   uploaded-by-user    procesed      warnings   upload-type  .       " [row2-end] 
                / 80px 2fr 2fr 1fr 1fr 1fr 80px;
        }
      }
    }
  }
}

.appeal-card-list {
  .info {
    .qualification { grid-area: qualification; }
    .appeal-type { grid-area: appeal-type; }
    .number-of-learners { grid-area: number-of-learners; }
    .status { grid-area: status; }        
  }
  .appeal-card .info {
     grid: [row1-start] "i qualification       actions " [row1-end] 
        [row2-start] ". appeal-type         .       " [row2-end] 
        [row3-start] ". number-of-learners  .       " [row3-end] 
        [row4-start] ". status              .       " [row4-end] 
        / 80px 1fr 80px;
    @media all and (min-width: 700px) {
      grid: [row1-start] "i qualification qualification       qualification  actions  "[row1-end] 
        [row2-start] "i appeal-type   number-of-learners  status         .        "[row2-end] 
        / 80px 1fr 1fr 1fr 80px;
    }
    @media all and (min-width: 1200px) {
      grid: [row1-start] "i qualification appeal-type number-of-learners  status actions "[row1-end] 
            / 80px 3fr 2fr 2fr 2fr 80px;
    }
  }
}

.appeal-evidence-card-list {
  .gr-btn {
    width: 200px;
    margin-left: 0px;
  }
}

.appeal-evidence-card {
   .card {
     background-color: $neutral-light-two;
   }
  .info {
    display: grid;
    padding: 0px 12px;
    .filename { grid-area: filename; }
    .added-date { grid-area: added-date; }
    .username { grid-area: username; }
    .file-status { grid-area: file-status; }  
    grid: [row1-start] "filename      actions" [row1-end] 
          [row2-start] "username      username" [row2-end] 
          [row3-start] "added-date    added-date" [row3-end] 
          [row4-start] "file-status   file-status" [row4-end]
          / 1fr 30px;
    @media all and (min-width: 1000px) {
      grid: [row1-start] "filename username added-date file-status actions"[row1-end] 
            / 3fr 2fr 2fr 2fr 30px;
    }
  }
}

.select-all-learners .gr-form-element {
  padding-left: 12px;
  padding-bottom: 6px;
  .gr-checkbox {
    margin-bottom: 0px;
  }
}
.appeal-learner-card {
  .info {
    display: grid;
    grid: [row1-start] "i name " [row1-end] 
          [row2-start] ". dob  " [row2-end] 
          [row3-start] ". id    " [row3-end] 
          / 80px 1fr;
    @media all and (min-width: 800px) {
      grid: [row1-start] "i name dob id"[row1-end] 
            / 80px 2fr 1fr 1fr;
    }    
  }  
  .info.readonly {
    padding: 0px 12px;
    grid: [row1-start] "name " [row1-end] 
          [row2-start] "dob  " [row2-end] 
          [row3-start] "id   " [row3-end] 
          / 1fr;
    @media all and (min-width: 800px) {
      grid: [row1-start] "name dob id "[row1-end] 
            / 2fr 1fr 1fr;
    }
  }
}

.appeal-form {  
  .header .grid {
    display: grid;
    h1.page-header { grid-area: page-header; }
    h2.page-sub-header { grid-area: page-sub-header; }
    .stepper { grid-area: stepper;}
    grid: [row1-start] "page-header     " [row1-end] 
          [row2-start] "page-sub-header " [row2-end] 
          [row3-start] "stepper         " [row3-end];
    @media all and (min-width: 800px) {
        grid: [row1-start] "page-header     stepper         " [row1-end]
              [row2-start] "page-sub-header page-sub-header " [row2-end] 
              / 60% 40%;
    }
  }
  .appeal-form-step {
    display: flex;
    flex-direction: column;
    min-height: 55vh;
    padding: 8px;
    .step-panel {
     flex-grow: 1;
     margin-bottom: 18px;     
    }
    .step-buttons { 
      display: flex;
      justify-content: space-between;
    }
  }
}
ul {
  -webkit-box-shadow: none !important; 
  box-shadow: none !important; 
}

.terms-and-conditions {
  ul {
    list-style-type: none;
    margin-bottom: 24px;
  }
}

.appeal-details {
  .raised-by {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .ucas-learners {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .checkbox {
      width: 30px;
      margin-right:8px;
      align-self: flex-start;
    }
  }
  .detail {
    margin-bottom: 24px;
  }
  .card {
    background-color: $neutral-light-two;;
  }
}

.grey {
  color: $neutral-med-one;
  fill: $neutral-med-one;
}

.page {
  margin-bottom: 60px;
}

.header-action-button .gr-btn {
  margin-left: 0px;
}

.header-columns {
  width: 100%;
}

// APPEAL MANAGEMENT
.appeals-management-page {
  .jumbotron {
    display: flex; 
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0px !important;
  }
  pearson-tabs {
    background-color: $neutral-light-two;
  }   
  .filter-by-user {
    width: fit-content;
    margin-top: 1em;
  }
  .load-more-work-button {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 1em;
  }
}

.team-appeal-search {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
  align-items: center;
  .gr-btn {
    margin: 4px 0px;
  }
  #referenceCode {
    width: 400px;
  }
}

.team-appeal-card-list {
  width: 100%;
  margin-bottom: 2em;
  p {
    margin-top: 1em;
  }
  
}
.team-appeal-card .info {
  display: grid;
  grid-template-rows: auto !important;
  .allocated-to { grid-area: allocated-to; }
  .appeal-type { grid-area: appeal-type; }
  .date-received { grid-area: date-received; }
  .last-updated { grid-area: last-updated; }
  .learning-provider { grid-area: learning-provider; }
  .number-of-learners { grid-area: number-of-learners; } 
  .status { grid-area: status; } 
  .team { grid-area: team; } 
  .qualification-group { grid-area: qualification-group;}
  grid: [row1-start] "learning-provider   actions " [row1-end] 
        [row2-start] "status              .       " [row2-end] 
        [row3-start] "appeal-type         .       " [row3-end] 
        [row4-start] "number-of-learners  .       " [row4-end] 
        [row5-start] "team                .       " [row5-end] 
        [row6-start] "allocated-to        .       " [row6-end] 
        [row7-start] "date-received       .       " [row7-end] 
        [row8-start] "last-updated        .       " [row8-end]  
        [row9-start] "qualification-group .       " [row9-end]  
        / 1fr 50px; 
  @media all and (min-width: 1000px) {    
    grid: [row1-start] "learning-provider   learning-provider   status          actions " [row1-end] 
          [row2-start] "appeal-type         team                date-received   .       " [row2-end] 
          [row3-start] "number-of-learners  allocated-to        last-updated    .       " [row3-end]
          [row4-start] "qualification-group .                   .               .       " [row4-end]
          / 1fr 1fr 1fr 50px;   
  }
}
 
.fileline-error-card-list,
.learner-grade-card-list {
  margin-bottom: 60px;
}

.team-select {
  width: fit-content;
}

.close-appeal-form {
  .outcome {
    margin-bottom: 2em;
  }
}

.view-appeal-page {
  .header {
    margin-bottom: 1em;
  }
  .summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1em;
    .status {
      display: flex;
      flex-direction: column;
      dl { display: flex; 
        dt { width: 120px; }
      }
    }
  }
}

.pager {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1em;;
}