@import "~@pearson-ux/gravity-css/dist/gravity";
@import "./variables";

.stepper {  
  height: 35px;
  .step {
    display: flex;
    align-items: center;
    position: relative;
    .step-number {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      color: $neutral-high-two;
      z-index: 10;
    }    
    .step-number-selected {
      color: $white;
      font-weight: 800;
      background-color: $theme-one-dark;
    }
    .step-number-disabled { 
      border: 1px solid $neutral-high-two;   
      background-color: $white; 
    }
    .step-description {
      @extend .gr-meta;    
      color: $neutral-high-two;
    }
    .step-description-active {
      font-weight: 800;
      color: $neutral-high-one;
    }
  }  
  .progress-bar {    
    background-color: $neutral-high-two;
    position: absolute;
    height: 1px;
  }
  .progress-bar-active {
    height: 3px;
    background-color: $theme-one-dark;
  }
}

.stepper.horizontal {
  display: grid;
  column-gap: 3px;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  .step {
    flex-direction: column;
    justify-content: flex-start;  
    .step-description {
      text-align: center;
    }
  }
  .progress-bar {
    width: 100%;
    top: 49%;
    left: 50%;
  }

  .progress-bar-2 {
    width: 296%
  }
  .progress-bar-3 {
      width: 125%
  }
  .progress-bar-4 {
      width: 70%
  }
  .progress-bar-5 {
      width: 60%
  }  
}

.stepper.vertical {      
  .step {
    margin-bottom: 1.1rem;
    .step-description {
      margin-left: 1.2rem;
      padding-bottom: 3px;
    }
  }
  .progress-bar {
    height: 80px;
    width: 3px;
    top: 90%;
    left: 1.5%    
  }  
}