.sort {   
  display: flex;
  align-content: flex-end;
  label.gr-meta {
    width: 75px;
    align-self: center;
    padding-top: 9px;
  }
  .search-container {
    display: flex;
    background-color: #ffffff;
    height: 36px;
    .gr-form-element:first-of-type {
      border: 0px;
    }
    .select {
      flex-grow: 1;
      margin-right: 3px;
    }
    .btn-container {
      border-left: 1px solid #C7C7C7;
      padding: 1px;
      width: 50px;      
    }
  }
}